const fetchql = async (query) => {
    var response = await fetch('http://localhost:4000/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: query
        }),
      })

    return response.json()
}

export default fetchql