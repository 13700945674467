<template>
  <div>
    <main>
      <div>
        <Carousel :images="product.photos" />
      </div>

      <div class="basic">
        <div>
          <h2>{{ product.name }}</h2>
        </div>

        <div class="info">
            <p class="price">R$ {{ product.price }}</p>
            <p class="description">{{product.description}}</p>
        </div>

        <div class="buttons">
            <div class="sizes">
                <button v-for="size in product.sizes" :key="size" @click="selectSize(size)" class="btn btn-light">{{size}}</button>
            </div>

            <button class="btn btn-primary btn-lg" @click="addToCart()">Add to cart</button>
        </div>

      </div>
    </main>
  </div>
</template>

<script>
import Carousel from "../components/Carousel";
import firebase from "firebase";
import store from '../store/index'

export default {
  name: "Overview",
  components: {
    Carousel,
  },
  data() {
    return {
      product: {},
      size: 'M'
    };
  },
  async mounted() {
    const db = firebase.firestore();

    const shirts = db.collection("shirts");
    const shirt = await shirts.where("id", "==", this.$route.params.id).get();

    shirt.forEach((doc) => (this.product = doc.data()));

    console.log(this.product)
  },
  methods: {
    selectSize(size){
      this.size = size
    },
    addToCart(){
      var {product, size} = this
      if(!size || size == undefined) return
      product.size = size

      store.commit('addItem', product)
    }
  }
};
</script>

<style scoped>
.basic {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}
.basic h2{
    color: black;
    font-weight: bold;
}
.price, .description {
    padding: 0;
    margin: 0;
  font-size: 20px;
  color: rgb(88, 88, 88);
}
.info{
    width: 350px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}
main {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.sizes{
    display: flex;
    margin-bottom: 15px;
}
.sizes button{
    margin-right: 10px;
}
.buttons{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
</style>