<template>
    <div>
      <div class="search-container">
        <h2 class="call">Search here:</h2>
        <ais-instant-search
            :search-client="searchClient"
            index-name="clothes"
            class="d-flex flex-column">

            <div class="d-flex flex-column align-items-end">
                <ais-search-box :class-names="{'ais-SearchBox-input': 'search'}" class="w-100"/>
                <ais-powered-by class="mt-3"/>
            </div>

            <div class=" mt-2">
                <!-- <div>
                    <ais-refinement-list operator="or" class="refine" attribute="category" />
                </div> -->

                <div>
                    <ais-hits class="hits">
                        <ul class="grid p-0" slot-scope="{ items }">
                            <li class="d-flex flex-column item-container w-100" v-for="item in items" :key="item.objectID">
                            <router-link class="title" :to="'/product/' + item.id" >
                                <img :src="item.photos[0]">
                                <div>
                                    <router-link class="title" :to="'/product/' + item.id" >{{ item.name }} - R${{item.price}}</router-link>
                                </div>
                            </router-link>
                            </li>
                        </ul>

                    </ais-hits>

                    <!-- <ais-pagination class="mr-5" :show-first="false"  :show-last="false"/> -->
                </div>
            </div>
        </ais-instant-search>

       </div>
    </div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import 'instantsearch.css/themes/satellite.css'

export default {
    name: "Search",
    data(){
        return {
          searchClient: algoliasearch(
            "9UH6B5LVFW",
            "270dd6f4466e2c8e90b12c8e16426c1e"
      ),
        }
    }
}
</script>

<style scoped>
  .search-container{
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .call{
      margin-bottom: 25px;
  }
  .d-flex{
      width: 700px;
      align-items: flex-start;
  }
  .ais-SearchBox-input{
      max-width: 680px !important;
      margin-left: 10px;
  }
  .item-container{
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;
      padding: 1em;
      border-radius: 5px;
  }
  .item-container:hover{
      cursor: pointer
  }
  .item-container img{
      width: 100%;
  }
  .item-container div{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
  }
  .grid{
      display: grid;
      width: 700px;
      grid-template-columns: repeat( auto-fit, minmax(231px, 1fr) );
      padding: 0;
  }
  .item-container .title{
      font-style: none;
      color: black;
      font-weight: 500;
  }
  .border{
      padding: 1em;
      border-radius: 5px;
  }
  .hits{
      width: 700px;
      margin: 50px 50px 0 0;
  }
  .refine{
      width: 160px;
  }
  .c-refine{
      width: 546px;
      border: 1px solid rgb(218, 218, 218);
      padding: 0.2em;

  }
</style>


