<template>
  <div class="cart">
    <div class="main">
      <h2>Items on cart:</h2>

      <div class="d-flex">
        <div v-if="items.length > 0">
          <div v-for="item in items" class="product" :key="item">
            <img :src="item.photos[0]" width="50px" height="50px" />
            <p>{{ item.name }}</p>
            <button class="btn btn-danger" @click="deleteItem(item.id)">
              Delete
            </button>
          </div>
        </div>

        <div v-else>
          <h3>Your shopping bag is empty.</h3>
          <p>
            <router-link to="/">Shop</router-link> or
            <router-link to="/search">search products</router-link>
          </p>
        </div>

        <div class="card w-25 p-4 shadow">
          <h4>Items:</h4>
          <p>R$ {{ amount }}</p>

          <h4>Shipping costs:</h4>
          <div class="d-flex">
            <input id="frete-input"  class="form-control" />
            <button @click.prevent="calcFrete()" class="btn btn-light">Ok</button>
          </div>

          <div v-if="frete != undefined">
            <p>Shipping time: {{frete.prazo.prazo}} days maximum</p>
            <p>Shipping costs: {{frete.prazo.preco}}</p>
            </div>

          <button class="btn btn-primary mt-2">Proceed to checkout</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store/index";
import fetchql from '../helpers/fetchql'

export default {
  name: "Cart",
  data() {
    return {
      items: [],
      frete: undefined
    };
  },
  computed: {
    amount() {
      return this.totalAmount();
    }
  },
  mounted() {
    this.items = store.getters.getItems;
    console.log(this.items);
  },
  methods: {
    deleteItem(id) {
      store.commit("removeItem", id);
      this.items = store.getters.getItems;
    },
    totalAmount() {
      var amount = 0;

      this.items.forEach((element) => {
        amount += element.price;
      });

      return amount;
    },
    async calcFrete(){
      const city_to = document.getElementById('frete-input').value
      const query = `query{
            prazo(city_from: "98700000", city_to: "${city_to}"){
              prazo
              preco
              error
            }
          }`

      var result = await fetchql(query)
      this.frete = result.data
    }
  },
};
</script>

<style scoped>
.product {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid rgb(201, 201, 201);
  border-top: 1px solid rgb(201, 201, 201);
  width: 500px;
  padding: 1.5em 0.5em;
}
h2 {
  margin: 30px 0 50px;
}
.card {
  margin-left: 400px;
}
.cart {
  display: flex;
  padding: 1em;
  justify-content: center;
}
.main {
  width: 80%;
}
</style>