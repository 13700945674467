<template>
  <div class="main">
    <div class="d-flex flex-column text">
      <h3 class="">Sustainable, well-made clothing</h3>
      <p>Fell comfortable in sustainably-produced, well-made and animal-fiendly clothes.</p>
      <button class="btn">See clothing</button>
    </div>

    <div>
        <img src="https://assets.bancointer.com.br/static/5ab494a21a043c51a6cbc87079235e11/d085d/inter-seguros-home.png">
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
.main{
 display: flex;
 justify-content: space-between;
 width: 100%;
 align-items: center;
 height: 70vh;
 background-color: rgb(185, 142, 86);
 padding: 0 2em;
}
.text h3{
    color: #fff;
    font-weight: bold;
}
.text p{
    font-size: 20px;
    width: 400px;
    color: #fff;
}
.btn{
    width: 200px;
    background: rgb(81, 128, 216);
    color: white;
    padding: 1em;
}
</style>