import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        cartKeys: [],
        cartItems: []
    },
    mutations: {
        cartKeys(state, keys){
            state.cartKeys = keys
        },
        addItem(state, newItem){
            state.cartItems.push(newItem)
        },
        removeItem(state, itemID){
            state.cartItems = state.cartItems.filter(item => item.id != itemID)
        }
    },
    actions: {

    },
    getters: {
        getItems(state){
            return state.cartItems
        }
    }
})

export default store