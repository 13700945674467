<template>
  <div class="home">
    <Hero/>
    <div class="products">
      <h2>Featured Clothing</h2>
      <div class="d-flex justify-content-center">
        <div v-for="shirt in shirts" :key="shirt">
          <Product :shirt="shirt" />
        </div>
      </div>
      <router-link to="/search" class="btn btn-primary">See all products</router-link>
    </div>

    <Features/>
  </div>
</template>

<script>
// @ is an alias to /src
import Product from "@/components/Product";
import firebase from "firebase";
import Hero from '../components/Hero.vue'
import Features from '../components/Features.vue'

export default {
  name: "Home",
  components: {
    Product,
    Features,
    Hero
  },
  data() {
    return {
      shirts: [],
    };
  },
  async mounted() {
    const db = firebase.firestore();

    var collection = await db.collection("shirts").get();

    collection.docs.forEach((doc) => {
      this.shirts.push(doc.data());
    });

    console.log(this.shirts);
  },
};
</script>

<style scoped>
@import "~mdb-ui-kit/css/mdb.min.css";
.home{
  width: 100%;
}
.products {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
}
.products h2 {
  width: 100%;
  width: 500px;
  margin-bottom: 30px;
  color: black;
  text-align: center;
}
</style>
