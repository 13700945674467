<template>
  <div class="cl">

  <img class="" :src="shirt.photos[0]" height="200px" alt="Card image cap"/>

  <!-- Card content -->
  <div class="card-body">
    <!-- Title -->
    <h4 class="card-title"><a>{{shirt.name}}</a></h4>
    <!-- Text -->
    <p class="card-text">
      R$ {{shirt.price}}
    </p>
  </div>
  </div>
</template>

<script>
export default {
  name: "Product",
  props: {
      shirt: Object,
  }
};
</script>

<style scoped>
@import '~mdb-ui-kit/css/mdb.min.css';

.cl{
    width: 250px;
    height: 350px;
    margin-right: 10px;
}
</style>