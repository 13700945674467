<template>
  <img :src="images[0]">
</template>

<script>


export default {
  name: "Carousel",
  components: {
      
  },
  props: {
    images: Array,
  },
};
</script>

<style scoped>
@import "~mdb-ui-kit/css/mdb.min.css";

.car{
    max-width: 600px;
}
img{
    border-radius: 8px;
    width: 600px;
    height: 450px;
}

</style>