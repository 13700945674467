<template>
  <div id="app">
    <Header/>
    <router-view/>
  </div>
</template>

<script>
import Header from './components/Header'
export default {
    name: "App",
    components: {
        Header
    }
}
</script>
<style>
*{
  box-sizing: border-box;
  padding: 0;
}
#app {
  font-family: "Source Sans Pro", sans-serif;
  width: 100%;
}
</style>
