import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Overview from '../views/Overview.vue'
import Cart from '../views/Cart.vue'
import Search from '../views/Search.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/', name: 'Home', component: Home},
  {path: '/about', name: 'About',  component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')},
  {path: '/product/:id', name: 'Overview', component: Overview},
  {path: '/cart', name: 'Cart', component: Cart},
  {path: '/search', name: 'Search', component: Search},
  {path: '/login', component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
