import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Hotjar from 'vue-hotjar'
import firebase from 'firebase'
import {firebaseConfig} from './helpers/firebaseConfig'
import InstantSearch from 'vue-instantsearch'

Vue.config.productionTip = false

Vue.use(InstantSearch)

Vue.use (Hotjar, {
  id: '2379358' 
})


new Vue({
  router,
  created(){
    firebase.initializeApp(firebaseConfig)
    firebase.analytics()
  },
  render: h => h(App)
}).$mount('#app')
