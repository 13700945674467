<template>
  <header>
    <div class="logo"><router-link to="/"><h1>Strade</h1></router-link></div>

    <ul class="menu">
      <li><router-link to="/search"><ion-icon name="search-outline"></ion-icon></router-link></li>
      <li><router-link to="/login"><ion-icon name="person-circle-sharp"></ion-icon></router-link></li>
      <li><router-link to="/cart"><ion-icon name="bag-handle-outline"></ion-icon> {{numberItems}}</router-link></li>
    </ul>
  </header>
</template>

<script>
import store from '../store/index'

export default {
  name: "Header",
  computed: {
    numberItems(){
      return store.getters.getItems.length
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '~mdb-ui-kit/css/mdb.min.css';
header {
  padding: 0 2em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, .2);
  height: 80px;
}
.logo h1{
  font-size: 20px;
  color: #000;
}
.menu{
  display: flex;
  list-style: none;
  padding: 0;
  width: 200px;
  margin-top: 10px;
  justify-content: space-around;
  align-items: center;
  color: #000
}
.menu li, .menu li a{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.form-control{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid rgb(170, 170, 170);
}
ion-icon{
  font-size: 22px;
}

</style>
