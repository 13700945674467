<template>
  <div class="main">
      <div>
            <img class="rounded" height="400px" width="auto" src="https://thumbs.dreamstime.com/b/packaging-process-last-step-shipping-packaging-process-shipping-100713086.jpg">
      </div>

    <div class="d-flex text">
        <h3>Our values</h3>
      <ul>
          <li><ion-icon name="leaf-outline"></ion-icon> Sustainably produced and packaged</li>
          <li><ion-icon name="wallet-outline"></ion-icon> Amazing price and quality</li>
          <li><ion-icon name="fitness-outline"></ion-icon> Animal cruelty free</li>
          <li><ion-icon name="chatbubbles-outline"></ion-icon> Great treatment</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
.main{
 display: flex;
 justify-content: space-around;
 width: 100%;
 align-items: center;
 height: 70vh;
 background-color: rgb(185, 142, 86);
 padding: 0 2em;
}
ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: flex-start;
}
ion-icon{
    color: rgb(100, 241, 100);
    margin-right: 10px;
}
.text{
    align-items: flex-start;
    flex-direction: column;
}
li{
    font-size: 25px;
    width: 600px;
    color: #fff;
    display: flex;
    align-items: center;
}
h3{
    color: white;
}
/* .text h3{
    color: #fff;
    font-weight: bold;
}

.btn{
    width: 200px;
    background: rgb(81, 128, 216);
    color: white;
    padding: 1em;
} */
</style>